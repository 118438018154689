import styled from '@emotion/styled';

import { Color, FontWeight, Margin } from '../../theme';

export const Container = styled.div`
  background-color: ${Color.Primary};
  scroll-margin-top: ${Margin.NavBarHeight};
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 50px ${Margin.MobileEdge};
  max-width: 680px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: ${FontWeight.Bold};
`;

export const Separator = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${Color.Black};
  margin-top: 3rem;
`;

export const Text = styled.p`
  margin-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.5;
`;

export const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  height: 48px;
  border-radius: 24px;
  background-color: ${Color.White};
  border: none;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  font-size: 1.4rem;
  padding: 0 15px;
  transition: 0.3s;

  :hover {
    background-color: ${Color.Black};
    color: ${Color.White};
  }
`;
