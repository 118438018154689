import { FooterProps } from './footer.props';
import { Container } from './footer.styles';

export default function Footer({ className }: FooterProps) {
  return (
    <Container className={className}>
      Vertrust &copy; {new Date().getFullYear()}
    </Container>
  );
}
