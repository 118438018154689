import styled from '@emotion/styled';
import { Margin } from '../theme';

export const Container = styled.div``;

export const Content = styled.div`
  padding: 50px 0;
  min-height: calc(100vh - ${Margin.NavBarHeight} - ${Margin.FooterHeight});
  margin: 0 auto;
`;
