import styled from '@emotion/styled';

import JobListItemBase from '../../components/jobListItem/jobListItem.component';
import { BoxShadow, Color, Margin } from '../../theme';

export const Container = styled.article``;

export const Title = styled.p`
  box-shadow: ${BoxShadow.Standard};
  background-color: ${Color.White};
  padding: 20px ${Margin.MobileEdge};
  color: ${Color.Primary};
  font-size: 2.6rem;
  line-height: 1.2;
`;

export const JobListItem = styled(JobListItemBase)`
  margin-top: 20px;
`;
