import styled from '@emotion/styled';
import { css } from '@emotion/react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { Color, FontWeight, Margin, Media } from '../../theme';

import { ItemProps } from './navBar.props';

export const Container = styled(AppBar)`
  position: sticky;
  top: 0;
`;

export const Content = styled(Toolbar)`
  padding: 0 ${Margin.MobileEdge};

  ${Media.Medium} {
    padding: 0 ${Margin.TabletEdge};
  }
`;

export const Logo = styled.a`
  font-size: 3.6rem;
  text-decoration: none;
  color: ${Color.White};
  display: flex;
  align-items: center;
  font-family: 'Roboto';

  ${Media.Medium} {
    font-size: 4.2rem;
  }
`;

export const Bold = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const Items = styled.ul`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const Item = styled.li<ItemProps>`
  margin-left: 20px;
  font-size: 1.6rem;
  transition: 0.3s;

  :hover {
    color: ${Color.White};
  }

  ${({ $active }: ItemProps) => $active && css`
    text-decoration: underline;
    color: ${Color.White};
    pointer-events: none;
  `}
`;
