import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

import { Color } from '../../theme';

export const Container = styled.article``;

export const GoBackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: ${Color.GrayMedium};
  transition: 0.3s;

  :hover {
    color: ${Color.Primary};
  }
`;

export const ArrowBack = styled(ArrowBackIos)`
  font-size: 2.4rem;
`;
