import styled from '@emotion/styled';

import { Media, Margin } from '../../theme';

export const Container = styled.main`
  ${Media.Medium} {
    margin: 0 auto;
    max-width: calc(900px + 2 * ${Margin.TabletEdge});
    padding: 0 ${Margin.TabletEdge};
  }
`;
