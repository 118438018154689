import { useTranslation } from 'react-i18next';
import { Path } from '../../enums';

import { TopBarProps } from './topBar.props';

import { Container, GoBackLink, ArrowBack } from './topBar.styles';

export default function TopBar({ className }: TopBarProps) {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <GoBackLink to={`/${Path.Jobs}`}>
        <ArrowBack />
        {t('topBar.jobs')}
      </GoBackLink>
    </Container>
  );
}
