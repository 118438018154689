import styled from '@emotion/styled';

import {
  BoxShadow,
  Button,
  Color,
  Input as InputBase,
  Margin,
  Checkbox as CheckboxBase,
} from '../../theme';

export const Container = styled.article``;

export const Title = styled.p`
  box-shadow: ${BoxShadow.Standard};
  background-color: ${Color.White};
  padding: 20px ${Margin.MobileEdge};
  color: ${Color.Primary};
  font-size: 2.6rem;
  line-height: 1.2;
`;

export const Form = styled.form`
  background-color: ${Color.White};
  padding: 50px ${Margin.MobileEdge};
  box-shadow: ${BoxShadow.Standard};
  margin-top: 20px;
`;

export const Content = styled.div``;

export const Input = styled(InputBase)`
   & + & {
    margin-top: 20px;
   }
`;

export const Submit = styled(Button)`
  margin-top: 50px;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 20px;
`;
