import JobForm from '../../modules/jobForm/jobForm.component';

import { Container } from './addJobFormPage.styles';

export default function AddJobFormPage() {
  return (
    <Container>
      <JobForm />
    </Container>
  );
}
