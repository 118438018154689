import styled from '@emotion/styled';

import {
  BoxShadow,
  Color,
  Margin,
  Input as InputBase,
  Button as ButtonBase,
} from '../../theme';

export const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.Standard};
  padding: 50px ${Margin.MobileEdge};
`;

export const Input = styled(InputBase)`
  & + & {
    margin-top: 20px;
  }
`;

export const Button = styled(ButtonBase)`
  display: block;
  margin: 50px auto 0;
`;
