import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LandingPageAboutUs from '../../modules/landingPageAboutUs/landingPageAboutUs.component';
import LandingPageHeader from '../../modules/landingPageHeader/landingPageHeader.component';
import JobList from '../../modules/jobList/jobList.component';
import { Path } from '../../enums';

import { Container, JobListSection, JobListLink } from './landingPage.styles';
import LandingPageContact from '../../modules/landingPageContact/landingPageContact.component';

export default function LandingPage() {
  const { t } = useTranslation();
  const [aboutUsScrollFlag, setAboutUsScrollFlag] = useState(false);

  const handleScrollToAboutUs = () => {
    setAboutUsScrollFlag(true);
    setTimeout(() => {
      setAboutUsScrollFlag(false);
    }, 300);
  };

  return (
    <Container>
      <LandingPageHeader onScrollToAboutUs={handleScrollToAboutUs} />
      <LandingPageAboutUs scrollFlagEnabled={aboutUsScrollFlag} />
      <JobListSection>
        <JobList title={t('landingPage.newOffers')} />
        <JobListLink to={Path.Jobs}>{t('landingPage.seeMore')}</JobListLink>
      </JobListSection>
      <LandingPageContact />
    </Container>
  );
}
