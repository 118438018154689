import { FormControlLabel } from '@mui/material';
import { JobFormProps } from './jobForm.props';
import {
  Container,
  Title,
  Form,
  Content,
  Input,
  Submit,
  Checkbox,
} from './jobForm.styles';
import { useTranslation } from 'react-i18next';

export default function JobForm({ className }: JobFormProps) {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Title>{t('jobForm.addJob')}</Title>
      <Form>
        <Content>
          <Input type="text" label={t('jobForm.jobTitle')} />
          <Input type="text" label={t('jobForm.companyName')} />
          <Input type="text" label={t('jobForm.salary')} />
          <Input type="text" label={t('jobForm.diet')} />
          <Input type="text" label={t('jobForm.languages')} />
          <FormControlLabel
            control={<Checkbox />}
            label={t('jobForm.driverLicense')}
          />
          <Input type="text" label={t('jobForm.additionalPermissions')} />
          <Input type="text" label={t('jobForm.jobDescription')} multiline minRows={4} />
          <Input type="text" label={t('jobForm.expirationDate')} />
        </Content>
        <Submit variant="contained">{t('jobForm.add')}</Submit>
      </Form>
    </Container>
  );
}
