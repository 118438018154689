import styled from '@emotion/styled';

import TopBarBase from '../../modules/topBar/topBar.component';
import { BoxShadow, Color, Margin, Media } from '../../theme';

export const Container = styled.div``;

export const TopBar = styled(TopBarBase)`
  max-width: calc(900px + 2 * ${Margin.TabletEdge});
  padding: 0 ${Margin.MobileEdge};

  ${Media.Medium} {
    margin: 0 auto;
    padding: 0 ${Margin.TabletEdge};
  }
`;

export const Header = styled.header`
  background-color: ${Color.GrayMedium};
  box-shadow: ${BoxShadow.Standard};
  margin-top: 40px;
  padding: 50px ${Margin.MobileEdge};

  ${Media.Medium} {
    padding: 50px ${Margin.TabletEdge};
  }
`;

export const Title = styled.p`
  max-width: 900px;
  margin: 0 auto;
  color: ${Color.White};
  font-size: 2.8rem;
`;

export const ContentWrapper = styled.div`
  margin: 40px 0 0;
  max-width: calc(900px + 2 * ${Margin.TabletEdge});

  ${Media.Medium} {
    margin: 40px auto 0;
    padding: 0 ${Margin.TabletEdge};
  }
`;

export const Content = styled.article`
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.Standard};
  padding: 50px ${Margin.MobileEdge};
`;
