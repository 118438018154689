import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Place from '@mui/icons-material/Place';
import Paid from '@mui/icons-material/Paid';
import { Link } from 'react-router-dom';

import { BoxShadow, Color, Margin } from '../../theme';

export const Container = styled(Link)`
  display: block;
  box-shadow: ${BoxShadow.Standard};
  background-color: ${Color.White};
  padding: 20px ${Margin.MobileEdge};
  border: 2px solid transparent;
  transition: 0.3s;
  
  :hover {
    border: 2px solid ${Color.Primary};
  }
`;
export const Position = styled.p`
  font-size: 2.6rem;
  line-height: 1.2;
  color: ${Color.Primary};
`;
export const Location = styled.p`
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 1.6rem;
`;
export const Salary = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.4;
  margin-top: 15px;
`;
export const Description = styled.p`
  margin-top: 20px;
  font-size: 1.6rem;
  line-height: 1.4;
`;
export const CreationDate = styled.p`
  color: ${Color.GrayMedium};
  margin-top: 20px;
  font-size: 1.6rem;
`;
const iconStyles = css`
  margin-right: 10px;
  color: ${Color.Primary};
  font-size: 2.4rem;
`;
export const PlaceIcon = styled(Place)`
  ${iconStyles}
`;
export const PaidIcon = styled(Paid)`
  ${iconStyles}
`;
