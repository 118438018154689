import { useTranslation } from 'react-i18next';

import jobs from '../../mocks/jobList.json';

import { JobListProps } from './jobList.props';
import { Container, Title, JobListItem } from './jobList.styles';

export default function JobList({ className, title }: JobListProps) {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Title>{title || t('jobList.jobs')}</Title>
      {jobs.map((job) => (
        <JobListItem
          key={job.id}
          id={job.id}
          position={job.title}
          location={job.cityName}
          salaryPerHour={job.salaryAmountPerHour}
          dietSalaryPerHour={job.dietSalaryPerHour}
          description={job.description}
          creationTimestamp={job.creationTimestamp}
        />
      ))}
    </Container>
  );
}
