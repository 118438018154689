import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Content,
  Title,
  Separator,
  Text,
  LinkButton,
} from './landingPageAboutUs.styles';
import { LandingPageAboutUsProps } from './landingPageAboutUs.props';

export default function LandingPageAboutUs({ scrollFlagEnabled }: LandingPageAboutUsProps) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && scrollFlagEnabled) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollFlagEnabled]);

  return (
    <Container ref={ref}>
      <Content>
        <Title>{t('landingPageAboutUs.title')}</Title>
        <Separator />
        <Text>{t('landingPageAboutUs.text1')}</Text>
        <Text>{t('landingPageAboutUs.text2')}</Text>
        <LinkButton href="mailto:kontakt@vertrust.pl">{t('landingPageAboutUs.sendEmail')}</LinkButton>
      </Content>
    </Container>
  );
}
