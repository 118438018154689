import styled from '@emotion/styled';
import { BoxShadow, Color, Margin } from '../../theme';

export const Container = styled.footer`
  background-color: ${Color.GrayMedium};
  padding: 50px ${Margin.MobileEdge};
  box-shadow: ${BoxShadow.Footer};
  font-size: 1.6rem;
  text-align: center;
  color: ${Color.White};
`;
