import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Color, FontWeight, Margin, Media } from '../../theme';

export const Container = styled.div`
  position: relative;
  top: -50px;
`;

export const JobListSection = styled.div`
  margin: 50px 0;

  ${Media.Medium} {
    margin: 50px ${Margin.TabletEdge};
  }
`;

export const JobListLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem auto 0;
  height: 48px;
  width: fit-content;
  border-radius: 24px;
  background-color: ${Color.Primary};
  border: none;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  font-size: 1.4rem;
  padding: 0 15px;
  transition: 0.3s;

  :hover {
    color: ${Color.White};
  }
`;
