import { Outlet, useLocation } from 'react-router-dom';

import NavBar from '../modules/navBar/navBar.component';
import Footer from '../modules/footer/footer.component';

import { Container, Content } from './app.styles';
import { useEffect } from 'react';

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <NavBar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Container>
  );
}
