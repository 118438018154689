import { useTranslation } from 'react-i18next';

import {
  Container,
  Content,
  Title,
  Separator,
  Text,
  Link,
  Contact,
} from './landingPageContact.styles';

export default function LandingPageContact() {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t('landingPageContact.title')}</Title>
        <Separator />
        <Text>
          {t('landingPageContact.text1')}
          <Link href="mailto:kontakt@vertrust.pl">
            {` ${t('landingPageContact.sendEmail')} `}
          </Link>
          {t('landingPageContact.or')}
          <Link href="tel:+48888915358">
            {` ${t('landingPageContact.call')} `}
          </Link>
          {t('landingPageContact.text2')}
        </Text>
        <Contact>
          {t('landingPageContact.email')} <Link href="mailto:kontakt@vertrust.pl">kontakt@vertrust.pl</Link>
        </Contact>
        <Contact>
          {t('landingPageContact.phone')} <Link href="tel:+48888915358">+48 888 915 358</Link>
        </Contact>
      </Content>
    </Container>
  );
}
