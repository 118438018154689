import { useTranslation } from 'react-i18next';
import {
  Container,
  Input,
  Button,
} from './loginPage.styles';

export default function LoginPage() {
  const { t } = useTranslation();

  return (
    <Container>
      <Input type="text" label={t('loginPage.login')} />
      <Input type="password" label={t('loginPage.password')} />
      <Button variant="contained">{t('loginPage.logIn')}</Button>
    </Container>
  );
}
