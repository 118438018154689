import JobList from '../../modules/jobList/jobList.component';

import { Container } from './jobListPage.styles';

export default function JobListPage() {
  return (
    <Container>
      <JobList />
    </Container>
  );
}
