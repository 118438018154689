import styled from '@emotion/styled';

import { Margin, Color, FontWeight } from '../../theme';

import laptopPic from './assets/laptopTable.jpg';

export const Container = styled.div`
  min-height: calc(100vh - ${Margin.NavBarHeight});
  background-image: url(${laptopPic});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 50px ${Margin.MobileEdge};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h2`
  color: ${Color.White};
  font-size: 4rem;
  line-height: 1.2;
  font-weight: ${FontWeight.Bold};
`;

export const Separator = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${Color.Primary};
  margin-top: 3rem;
`;

export const Text = styled.p`
  margin-top: 3rem;
  color: ${Color.White};
  font-size: 1.6rem;
`;

export const Button = styled.button`
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  font-size: 1.4rem;
  background-color: ${Color.Primary};
  padding: 0 15px;
  height: 48px;
  border-radius: 24px;
  border: none;
  transition: 0.3s;
  color: ${Color.Black};

  :hover {
    color: ${Color.White};
  }
`;
