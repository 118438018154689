import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/Button';
import CheckboxBase from '@mui/material/Checkbox';

export const Input = styled(TextField)`
  width: 100%;
  
  input {
    font-size: 1.6rem;
  }
`;

export const Button = styled(ButtonBase)`
  font-size: 1.6rem;
`;

export const Checkbox = styled(CheckboxBase)``;
