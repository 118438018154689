import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { JobListItemProps } from './jobListItem.props';
import {
  Container,
  CreationDate,
  Description,
  Location,
  PaidIcon,
  PlaceIcon,
  Position,
  Salary,
} from './jobListItem.styles';
import { Path } from '../../enums';

export default function JobListItem({
  className,
  id,
  position,
  location,
  salaryPerHour,
  dietSalaryPerHour,
  description,
  creationTimestamp,
}: JobListItemProps) {
  const { t } = useTranslation();

  return (
    <Container className={className} to={`/${Path.Jobs}/${id}`}>
      <Position>{position}</Position>
      <Location>
        <PlaceIcon />
        {location}
      </Location>
      <Salary>
        <PaidIcon />
        {t('jobListItem.salary')}<br />
        {salaryPerHour} {t('jobListItem.eur')} ({t('jobListItem.diet')} {dietSalaryPerHour} {t('jobListItem.eur')})
      </Salary>
      <Description>{description}</Description>
      <CreationDate>{t('jobListItem.creationDate')} {dayjs(creationTimestamp).format('D.MM.YYYY')}</CreationDate>
    </Container>
  );
}
