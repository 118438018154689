import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Path } from '../../enums';

import { NavBarProps } from './navBar.props';
import {
  Bold,
  Container,
  Content,
  Logo,
  Items,
  Item,
} from './navBar.styles';
import { useLocation } from 'react-router-dom';

export default function NavBar({ className }: NavBarProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Container className={className}>
      <Content>
        <Logo href={Path.Main}>V<Bold>€</Bold>rtrust</Logo>
        <Items>
          <Item $active={(pathname === `/${Path.Jobs}`)}>
            <Link to={`/${Path.Jobs}`}>{t('navBar.offers')}</Link>
          </Item>
          <Item $active={(pathname === Path.Main)}>
            <Link to={Path.Main}>{t('navBar.aboutUs')}</Link>
          </Item>
        </Items>
      </Content>
    </Container>
  );
}
