import { useTranslation } from 'react-i18next';

import {
  Container,
  Content,
  Title,
  Separator,
  Text,
  Button,
} from './landingPageHeader.styles';
import { LandingPageHeaderProps } from './landingPageHeader.props';

export default function LandingPageHeader({ onScrollToAboutUs }: LandingPageHeaderProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t('landingPageHeader.title')}</Title>
        <Separator />
        <Text>{t('landingPageHeader.text1')}</Text>
        <Text>{t('landingPageHeader.text2')}</Text>
        <Button onClick={onScrollToAboutUs}>
          {t('landingPageHeader.more')}
        </Button>
      </Content>
    </Container>
  );
}
