import styled from '@emotion/styled';

import { Color, FontWeight, Margin, Media } from '../../theme';

export const Container = styled.div`
  background-color: ${Color.Primary};
`;

export const Content = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 50px ${Margin.MobileEdge};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 2.6rem;
  font-weight: ${FontWeight.Bold};
`;

export const Separator = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${Color.Black};
  margin-top: 3rem;
`;

export const Text = styled.p`
  margin-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.5;
`;

export const Link = styled.a`
  font-weight: ${FontWeight.Bold};
  transition: 0.3s;

  :hover {
    text-decoration: underline;
    color: ${Color.White};
  }
`;

export const Contact = styled.p`
  font-size: 2.6rem;
  font-weight: ${FontWeight.Bold};
  margin-top: 30px;

  ${Media.Medium} {
    font-size: 3.2rem;
  }
`;
