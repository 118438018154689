import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pl from './pl.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'pl',
  resources: {
    pl: {
      translation: pl,
    }
  }
});

export default i18n;
