import { useParams } from 'react-router-dom';

import jobs from '../../mocks/jobList.json';

import {
  Container,
  TopBar,
  Header,
  Title,
  ContentWrapper,
  Content,
} from './jobDetailsPage.styles';

export default function JobDetailsPage() {
  const { id } = useParams();
  const job = jobs.find((job) => job.id === id);

  // TODO: Add 404 handling
  if (!job) return null;

  return (
    <Container>
      <TopBar />
      <Header>
        <Title>{job.title}</Title>
      </Header>
      <ContentWrapper>
        <Content>
          Job Details here
        </Content>
      </ContentWrapper>
    </Container>
  );
}
